import React, {useRef} from 'react'
import gsap from "gsap";
import ScrollSVG from "../../assets/scrolldown.inline.svg";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Link } from "gatsby"
gsap.registerPlugin(ScrollToPlugin);

export default function SliderControls() {

  const nextTrigger = useRef();
  const prevTrigger = useRef();

  // const handleClick = (e) => {
  //   if(e.target.classList.contains('next')){
  //     console.log(nextTrigger.current.getAttribute('data-next-trigger'));
  //     // gsap.to(window, {duration: 0.2, scrollTo:`.${nextTrigger.current.getAttribute('data-next-trigger')}`, offsetY:'-'+window.innerHeight/2+'px'});
  //     gsap.to(window, {duration: 0.2, scrollTo: {y: `.${nextTrigger.current.getAttribute('data-next-trigger')}`}});
  //   }
  //   if(e.target.classList.contains('prev')){
  //     console.log(prevTrigger.current.getAttribute('data-prev-trigger'));
      
  //     gsap.to(window, {duration: 0.2, scrollTo:`.${prevTrigger.current.getAttribute('data-prev-trigger')}`, offsetY:200});
  //     //gsap.to(window, {duration: 0.2, scrollTo: {y: `.${nextTrigger.current.getAttribute('data-prev-trigger')}`}});
  //   }
  // }

    return (
        <div className="slider-controls" data-active="false">
            {/* <div ref={prevTrigger} className="prev arrow" onClick={handleClick}>
                <div className="indent_text">Go to previous section</div>
            </div> */}
            
            {/* <Link to="/contact" className="get_in_touch">
              <span className="pseudo pseudo-2"></span>
              <span className="pseudo pseudo-1"></span>
              Get in touch
            </Link> */}
            
            {/* <div className="scroll scroll_down">
              <ScrollSVG />
              <span>Scroll</span>
            </div>	 */}
            {/* <div className="pagination">
              <div className="pagination-inner">	
                <div className="index-number">
                  <div className="inner">
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                  </div>
                </div>
                <div className="line">
                    <div className="line-inner"></div>
                    <div className="line-inner--timer"></div>
                </div>
                <div className="total-number">3</div>
              </div>
            </div> */}
            {/* <div ref={nextTrigger} className="next arrow" onClick={handleClick}>
                <div className="indent_text">Go to next section</div>
            </div> */}
            {/* <div className="pagination-dots">
              <ul>
                <li data-slideIndex="1"></li>
                <li data-slideIndex="2"></li>
                <li data-slideIndex="3"></li>
              </ul>
            </div> */}
        </div>
    )
}
