import React, {useEffect, useState, useRef} from "react"
import parse from 'html-react-parser'
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import gsap, {TimelineLite} from "gsap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function HomeSection8({isInview}) {

  const [theSwiper, setTheSwiper] = useState()
  const [autoplay, setAutoplay] = useState(false)
  
  const animateSlideInit = () => {
    const wordsArray = gsap.utils.toArray('.section7 h2 .words');
    const testimonailImage = gsap.utils.toArray('.section8 .slick-active .background-image');
    const testimonailImageOverlay = gsap.utils.toArray('.section8  .slick-active .background-image .colour-overlay');
    const testimonail = gsap.utils.toArray('.section8  .slick-active .testimonial');
    const testimonailName = gsap.utils.toArray('.section8 .slick-active .name'); 
    const animation = new TimelineLite()
    
    animation.to(wordsArray, {opacity:1, scale:1, y:0, stagger:0.2, duration:0.2})
             .to(testimonailImage, {opacity:1, scale:1, y:0, duration:0.2, delay:100})
             .to(testimonailImageOverlay, {opacity:1, duration:0.4, delay:'-0.4'})
             .to(testimonail, {opacity:1,  x:0, duration:0.2, delay:'-0.2'})
             .to(testimonailName, {opacity:1,  y:0, duration:0.2});
  }

  const clearProps = () => {
    console.log('clear props')
    const testimonailImage = gsap.utils.toArray('.section8 .slick-active .background-image');
    const testimonailImageOverlay = gsap.utils.toArray('.section8  .slick-active .background-image .colour-overlay');
    const testimonail = gsap.utils.toArray('.section8  .slick-active .testimonial');
    const testimonailName = gsap.utils.toArray('.section8 .slick-active .name'); 
    const animation = new TimelineLite()
    
    animation.set(testimonailImage, {clearProps:"all"})
            .set(testimonailImageOverlay, {clearProps:"all"})
            .set(testimonail, {clearProps:"all"})
            .set(testimonailName, {clearProps:"all"})
  }
 
  useEffect(() => {
    if(isInview === true){
      // console.log('go')
      animateSlideInit()
      // theSwiper.slickGoTo(0)
    }
  },[isInview])

  useEffect(() => {
    if(isInview === true){
      const slideLength = 2;
      let currentSlide = 1;
      
      const interval = setInterval(() => {
        // console.log('running')
          if(currentSlide <= slideLength){
            theSwiper.slickGoTo(currentSlide)
            currentSlide++;
          }else{
            theSwiper.slickGoTo(0)
            currentSlide = 0
          }
          // console.log(currentSlide)
          
      }, 9000);
      // return () => clearInterval(interval);
    }
  }, [isInview]);

  const settings = {
    dots: false,
    infinite: true,
    fade:true,
    autoplay:false,
    speed: 0,
    interval:0,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover:false,
    autoplaySpeed:0,
    draggable:false,
    adaptiveHeight: true,
    // beforeChange: (oldIndex, newIndex) => {
    //   if(isInview === true){
    //     // clearProps()
    //   }
    //   // console.log(oldIndex, newIndex)
    // }
  };

  return (
    <StaticQuery
      query={graphql`
        query HomeSection8Query {
          wpPage(id: {eq: "cG9zdDo4"}) {
            acf_homepage {
              testimonialSlide8 {
                quoteName
                quoteText
                quoteImage{
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={acf_homepage => (
        
          <section id="section8" className={isInview ? `section section8 home-section section--show-header is_inview  trans_in scollTrigger testimonial-wrap customIsInView` : `section section8 home-section section--show-header is_inview  trans_in scollTrigger testimonial-wrap`} >
            <Slider {...settings} ref={slider => (setTheSwiper(slider))}>
              {
                acf_homepage.wpPage.acf_homepage.testimonialSlide8.map(slide => {
                  return(
                    <>
                      <div className="testimonial-wrap">
                          <div className="testimonial">{parse(slide.quoteText)}</div>
                          <label className="name">{slide.quoteName}</label>
                      </div>
                      <div className="background-image">
                          <div className="inner">
                            <div className="colour-overlay"></div>
                            <div className="image-wrap">
                              <div className="tint"></div>
                              <Img 
                              fluid={slide.quoteImage?.localFile?.childImageSharp?.fluid} 
                              alt={slide.quoteImage?.altText} 
                              className="bg-cover BG bgStretch" />
                            </div>
                        </div>
                      </div>
                    </>
                  )
                })
              }
            
            </Slider>
        </section>
          
      )}
    />
  )
}
