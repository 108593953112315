import React, { Component } from 'react';
import parse from 'html-react-parser'
import ScrollSVG from "../../assets/scrolldown.inline.svg";
import Img from "gatsby-image"
import { Link } from "gatsby"


class HomeCaseStudyTemplate extends Component {
  render() {
    return (
      <section 
      id={`section-${this.props.index}`} 
      className={`section-${this.props.index} section-anim slider-section scollTrigger`} 
      data-color1={this.props.color1} 
      data-color2={this.props.color2} 
      data-start={this.props.start} 
      data-end={this.props.end}
      data-show-next={this.props.show_next}
      data-show-prev={this.props.show_prev}
      data-show-pagination={this.props.show_pagination}
      data-case-index={this.props.case_index}
      data-show-header="false"
      data-next-trigger={this.props.next_trigger}
      data-prev-trigger={this.props.prev_trigger}
      >
        {/* <div className="bg"></div> */}
          <div className="home-wrapper">
            <div className="before_wrap"><div className="rectangle-bg"></div></div>
            <div className="vert-center section-anim__content">
              <h2 className="label"><span>{this.props.label} Case Study</span></h2>
              <div id="section3_heading" className="split-text fakeHeading heading heading--small shadow" data-split-type="lines"><span>{this.props.headline}</span></div>
              <div className="text-wrap">
                <div className="before--wrap"></div>
                <div id="section3_text" className="desc-text">{parse(this.props.text)}</div>
                <Link to={this.props.button.internalLinkText} className="btn btn--grad"><span>{this.props.button.buttonText}</span></Link>
              </div>
            </div>
            <div className="anim_image_wrap">
              <div className="section-anim__image-mask">
              {this.props.image?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
              <Img 
              fluid={this.props.image?.localFile?.childImageSharp?.fluid} 
              alt={this.props.image?.altText} 
              className="section-anim__image section-anim__image-2" />
              </div>
            </div>
          </div>

      </section>
    );
  }
}

export default HomeCaseStudyTemplate;