import React, {useEffect, useState} from "react"
import { StaticQuery, graphql } from "gatsby"
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";

gsap.registerPlugin(SplitText);

export default function HomeSection7() {
  
  useEffect(() => {
    if(typeof document !== 'undefined'){

      
      // let text = heroText.innerHTML;
		  	var heroText_split = new SplitText('.section7 h2 .split', {type:"lines, words", linesClass:'lines', wordsClass:'words'});

        heroText_split.lines.map(line => {
          let grandparent = line.parentNode.parentNode;
          let parent = line.parentNode;
          let originalHtml = line.innerHTML;
          let childNode = line.childNodes;

          line.innerHTML = originalHtml;

          grandparent.append(line);
          Array.from(grandparent.querySelectorAll('.lines')).map(line => {
            setTimeout(function(){
              line.classList.add('is_inview')
            }.bind(this), 1);
              
          })
          parent.style.display = 'none';
        })
        

    }
    
  
  }, [])


  return (
    <StaticQuery
      query={graphql`
        query HomeSection7Query {
          wpPage(id: {eq: "cG9zdDo4"}) {
            acf_homepage {
              heading7
            }
          }
        }
      `}
      render={acf_homepage => (
       
            <section id="section7" className={`section section7 home-section section--show-header animated active trans_in scollTrigger`} data-addscrollbarsection="true" data-normal-scroll="false" data-cursor-label="scroll" data-cursor-size="large" data-bg-color="bg-white" data-apply-transform="true" data-show-pagination="false" data-show-triangles="false" data-show-landing-animations="false" data-show-lines="false" data-background-shapes-active="true" data-background-shapes-scale="0.3">
              <h2><span className="split">{acf_homepage.wpPage.acf_homepage.heading7}</span></h2>
            </section> 
      )}
    />
  )
}
