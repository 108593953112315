import React, {useState, useEffect, useRef} from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import {Link} from 'gatsby';

export default function HomeSection10() {
  const targetRef = useRef()
  const newPositionRef = useRef()
  const originalPositionRef = useRef()

  const repositionElement = () => {
      if(typeof window !== undefined){
          const target = targetRef.current
          const newPosition = newPositionRef.current
          const originalPosition = originalPositionRef.current

          if(window.innerWidth < 850 ){
              newPosition.parentNode.insertBefore(target, newPosition)
          }else{
              originalPosition.parentNode.insertBefore(target, originalPosition)
          }
      }
  }

  useEffect(()=> {
      repositionElement()

      if(typeof window !== undefined){
          window.addEventListener('resize', repositionElement)    
      }
     return () => {
          window.removeEventListener('resize', repositionElement)    
      }
  },[])

  return (
    <StaticQuery
      query={graphql`
        query HomeSection10BlogQuery {
          allWpBlog(sort: {order: DESC, fields: date}, filter: {blogCats: {nodes: {elemMatch: {slug: {ne: "news"}}}}}, limit: 4) {
            edges {
              node {
                title
                date(formatString: "Do MMMM YYYY")
                slug
                link
                id
                blogCats {
                  nodes {
                    slug
                  }
                }
                author {
                  node {
                    name
                    slug
                  }
                }
                acf_customFeaturedImage {
                  customFeaturedImage {
                    altText
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={blogPosts => (
        <>
          <div className="latest-blog-post-wrap">
            <h2 className="blog-label label"><a href="/insights/">Blog</a></h2>
            <span className="originalPosition"><span ref={originalPositionRef}></span></span>
            <article key={blogPosts.allWpBlog.edges[0].node.id} ref={targetRef}>
                <div className="image-link-wrap-outer">
                  <Link to={blogPosts.allWpBlog.edges[0].node.link}>												
                    <div className="image-wrap">
                      <div className="tint"></div>
                      {blogPosts?.allWpBlog?.edges[0]?.node?.acf_customFeaturedImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
                      <Img 
                      fluid={blogPosts?.allWpBlog?.edges[0]?.node?.acf_customFeaturedImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid} 
                      alt={blogPosts?.allWpBlog?.edges[0]?.node?.acf_customFeaturedImage?.customFeaturedImage?.altText} 
                      className="bg-cover BG bgStretch" />
                    </div>
                    <div className="indent_text">{blogPosts.allWpBlog.edges[0].node.title}</div>
                  </Link>
                </div>
                <div className="info">
                  <Link to={blogPosts.allWpBlog.edges[0].node.link}>	<h3>{blogPosts.allWpBlog.edges[0].node.title}</h3></Link>
                  <div className="author_date">by <Link to={"/author/"+blogPosts.allWpBlog.edges[0].node.author.node.name.replace(/\s+/g, '-').toLowerCase()+'/'}>{blogPosts.allWpBlog.edges[0].node.author.node.name}</Link> | {blogPosts.allWpBlog.edges[0].node.date}</div>
                </div>
              </article>
          </div>
          
          <div className="other-blog-posts-wrap">
            <label className="blog-label label"><a href="/insights/">Blog</a></label>
            <div className="newPosition"><div ref={newPositionRef}></div></div>
            {
              blogPosts.allWpBlog.edges.map((post, i) => {
                if(i !== 0){
                  return(
                    
                    <article key={post.node.id}>
                      <div className="image-link-wrap-outer">
                        <Link className="link" to={post.node.link}>
                          <div className="image-wrap">
                            <div className="tint"></div>
                            {post?.node?.acf_customFeaturedImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
                            <Img 
                            fluid={post?.node?.acf_customFeaturedImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid}
                            alt={post?.node?.acf_customFeaturedImage?.customFeaturedImage?.altText}
                            className="bg-cover BG bgStretch" />
                          </div>
                        </Link>
                      </div>
                      <div className="info">
                        <Link to={post.node.link}><h3>{post.node.title}</h3></Link>
                        <div className="author_date">by <Link to={'/author/'+post.node.author.node.name.replace(/\s+/g, '-').toLowerCase()+'/'}>{post.node.author.node.name}</Link> | {post.node.date}</div>
                      </div>
                    </article>
                  )
                }
            })}
          
          </div>
        </>
      )}
    />
  )
}
