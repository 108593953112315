import React, {useState} from "react"
import  HomeSection10Blog from './blog/HomeSection10Blog'
import VisibilitySensor from 'react-visibility-sensor';

const HomeSection10 = ({ data }) => {
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
    {({isVisible}) =>
      <section id="section10" className={`section section10 home-section section--show-header is_inview trans_in scollTrigger ${isInview ? 'active' : ""}`}>
        <div className="blog-wrap">
          <HomeSection10Blog/>
        </div>
      </section> 
    }
  </VisibilitySensor>
    
  )
}

export default HomeSection10
