import React from 'react'

export default function AnimatedRectangles() {
    return (
      
        <div className="animate-rectangles">
            <div className="rect-wrap">
                <div className="rect"></div>
            </div>
            <div className="rect-wrap">
                <div className="rect"></div>
            </div>
            <div className="rect-wrap">
                <div className="rect"></div>
            </div>
            <div className="rect-wrap">
                <div className="rect"></div>
            </div>
            <div className="rect-wrap">
                <div className="rect"></div>
            </div>
            
            <div className="rect-wrap">
                <div className="rect"></div>
            </div>
            <div className="rect-wrap">
                <div className="rect"></div>
            </div>
        </div>
    )
}
