import React, {useState, useEffect} from "react"
import parse from 'html-react-parser';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_video_transcript_block.scss";


const VideoTranscriptionBlock = ({ removeBottomMargin, text}) => {

  let classList = [
    'video-transcript-block',
    'contains-custom-colour',
    'section'
  ]; 
    
  if(removeBottomMargin === true){
    classList.push('no-margin');
  }
  
  const [isInview, setIsInview] = useState(false);

  
  useEffect(() => {
    if (typeof document !== 'undefined') {
     const transcriptButton = document.querySelector('.video-transcript-block button');
     const transcript = document.querySelector('.video-transcript-block .transcript');

      transcriptButton.addEventListener('click', function(){
        transcript.classList.toggle('is_open');

        if(transcript.classList.contains('is_open')){
          transcriptButton.innerHTML = '<span>Hide video transcript</span>';
        }else{
          transcriptButton.innerHTML = '<span>View video transcript</span>';
        }
      });
    }
  }, [])

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  return ( 
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`} data-bg-shape="show_shape">
          <div className="wrapper-900">
            <div className="wrapper-inner">
              <button><span>View video transcript</span></button>
              <div className="transcript">
                <p>
                 {parse(text)}
                </p>
              </div>
            </div>
          </div>
        </section>
      }
    </VisibilitySensor>

  )
}

export default VideoTranscriptionBlock

