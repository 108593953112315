import React, {useState} from "react"
import parse from 'html-react-parser'
import VisibilitySensor from 'react-visibility-sensor';
import { StaticQuery, graphql } from "gatsby"

export default function HomeSectionAdditionalTextArea() {
   
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    //console.log('section 9 inview')
    setIsInview(isVisible)
  }

  return (
    <StaticQuery
      query={graphql`
        query HomeSectionAdditionalTextAreaQuery {
          wpPage(id: {eq: "cG9zdDo4"}) {
            acf_homepage {
              additionalTextArea
            }
          }
        }
      `}
      render={acf_homepage => (
        <>
          <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
          {({isVisible}) =>
               <section 
              id="section-additionalText" 
              className={`section autoHeight sectionAdditionalText home-section section--show-header is_inview ${isInview ? 'active' : ''} trans_in`}
              >
                  <div className="wrapper">
                    {parse(acf_homepage.wpPage.acf_homepage.additionalTextArea)}
                  </div> 
              </section>
          }
        </VisibilitySensor>
        </>
      )}
    />
  )
}
