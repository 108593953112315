import React, {useState} from "react"
import parse from 'html-react-parser'
import LottieWrap from '../lottie/lottieWrap';
import {Link} from 'gatsby';
import { StaticQuery, graphql } from "gatsby"

export default function HomeSection6() {
  const handleHoverEnter = (e) => {
    const target = e.target.closest('article').querySelector('.btn')
    target.classList.add('js_hover')
  }
  const handleHoverLeave = (e) => {
    const target = e.target.closest('article').querySelector('.btn')
    target.classList.remove('js_hover')
  }

  const [isHovering, setIsHovering] = useState(true)

  return (
    <StaticQuery
      query={graphql`
        query HomeSection6Query {
          wpPage(id: {eq: "cG9zdDo4"}) {
            acf_homepage {
              servicesOffered6 {
                text
                service
                buttonText
                link {
                  ... on WpService {
                    id
                    link
                  }
                }
              }
            }
          }
        }
      `}
      render={acf_homepage => (
        <>
          <section 
          id="section-6" 
          className="section section6 home-section section--show-header is_inview active trans_in"
          >
            <div className="vert-center">
              <div className="wrapper">
                <div className="wrapper-offset">
                  <h2 className="label">Some of our digital marketing services</h2>
                    <ul className="the_services">
                      {acf_homepage.wpPage.acf_homepage.servicesOffered6.map((item) => {
                       return(
                        <li 
                          id={item.service.toLowerCase()+"_wrap"}  
                          key={item.service.toLowerCase()+"_wrap"}  
                          data-target={item.service.toLowerCase()} 
                        >
                          <article>
                            {/* <Link className="title_link link" to={item.link.link} 
                            onMouseEnter={(e) => handleHoverEnter(e)}  
                            onTouchStart={(e) => handleHoverEnter(e)}
                            onMouseLeave={(e) => handleHoverLeave(e)}  
                            onTouchEnd={(e) => handleHoverLeave(e)}> */}
                              <div className="title_link">
                              <div className="lottie-custom-wrap">
                                <LottieWrap forcePlay={isHovering} useParentHoverController={true} animation={item.service.toLowerCase()}></LottieWrap>
                              </div>
                              
                              <h3>{item.service}</h3>
                              </div>
                            {/* </Link> */}
                            <div className="desc_text">{parse(item.text)}</div>
                            <Link 
                            onMouseEnter={(e) => handleHoverEnter(e)}  
                            onTouchStart={(e) => handleHoverEnter(e)}
                            onMouseLeave={(e) => handleHoverLeave(e)}  
                            onTouchEnd={(e) => handleHoverLeave(e)}
                            to={item.link.link} className={`btn btn--grad link ${item.service.toLowerCase()}`}><span>{item.buttonText}</span></Link>
                          </article>
                        </li>
                       ) 
                      })}
                    </ul>
                    {/* <Link to={acf_homepage.wpPage.acf_homepage.button6.internalLinkText} className="btn btn--grad btn--grad--orange"><span>{acf_homepage.wpPage.acf_homepage.button6.buttonText}</span></Link> */}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    />
  )
}
