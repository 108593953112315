import React, { Component, createRef } from 'react';
import gsap from 'gsap';

class HomeBackgroundShapes extends Component {

  constructor(){
    super()
    this.lines = createRef()
    
  }

  linesAnimation = () => {
    const lines = Array.from(this.lines.current.querySelectorAll('.line'));
    const winH = window.innerHeight;
    let isAnimating = true
    lines.forEach((line , i) => {
      line.style.left = 100/lines.length*i+'%'
      gsap.to(
        line.querySelector('.line-inner'),  
        {
          height:100/lines.length*i+'%', 
          duration:1.5, 
          onComplete: function(){
            isAnimating = false;
          }
        }
      )
    })

    function updateLineHeight(e){
      var moveY = (e.pageY / window.innerHeight * 100) / 4
      
      if(isAnimating == false){
        lines.forEach((line , i) => {
          gsap.to(
            line.querySelector('.line-inner'),  {
              y:moveY * (lines.length - i)+'%', 
              duration:1.5
            })
        })
      }
    }

    window.addEventListener('mousemove', updateLineHeight, {passive: true})

    
    
  }

  componentDidMount(){
      this.linesAnimation()

    


  }

  render() {
    return (
      <div id="background_shapes" className="background start_triangles">
        
        <div className="big-rect"></div>
        
        <div className="square-wrap">
          <div className="small-square square"></div>
          <div className="big-square square"></div>
        </div>
        
        <div className="big-circ"></div>

        <div className="lines-wrapper" ref={this.lines}>
          <div className="line"><div className="line-inner"></div></div>
          <div className="line"><div className="line-inner"></div></div>
          <div className="line"><div className="line-inner"></div></div>
          <div className="line"><div className="line-inner"></div></div>
          <div className="line"><div className="line-inner"></div></div>
          <div className="line"><div className="line-inner"></div></div>
          <div className="line"><div className="line-inner"></div></div>
        </div>
        
        <div className="triangle-wrapper triangle-wrapper-1">
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
        </div>


        <div className="triangle-wrapper triangle-wrapper-2">
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
        </div>

        <div className="triangle-wrapper triangle-wrapper-3">
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
          <div className="triangle"></div>
        </div>
      </div>
    );
  }
}

export default HomeBackgroundShapes;
