import React from "react"
import HomeCaseStudyTemplate from '../homepage/HomeCaseStudyTemplate'
import { StaticQuery, graphql } from "gatsby"

export default function HomeSection2() {
  return (
    <StaticQuery
      query={graphql`
        query HomeSection2Query {
          wpPage(id: {eq: "cG9zdDo4"}) {
            acf_homepage {
              label2
              headline2
              text2
              button2 {
                buttonText
                externalLink
                internalLinkText
                internalOrExternalLink
              }
              image2 {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={acf_homepage => (
        <HomeCaseStudyTemplate 
          label = {acf_homepage.wpPage.acf_homepage.label2}
          headline = {acf_homepage.wpPage.acf_homepage.headline2}
          text = {acf_homepage.wpPage.acf_homepage.text2}
          button = {acf_homepage.wpPage.acf_homepage.button2}
          image = {acf_homepage.wpPage.acf_homepage.image2}
          color1="#322196" 
          color2="#2071fc"
          start="top 40%"
          end="top 100%"
          index="2" 
          show_next="true"
          show_prev="false"
          show_pagination="true"
          case_index="33.3333"
          next_trigger="trigger-2"
          prev_trigger="trigger-1"
        />
      )}
    />
  )
}
