import React, { Component } from 'react';

class HomeSection1Shapes extends Component {
  render() {
    return (
      <div className="before_wrap">
        <div className="rectangle-bg"></div>
        <div className="dashed-line">
          <div className="dot"></div>
          <div className="circle-wrapper">
            <div className="circle-inner">
            </div>
            <div className="circle-small">
              <div className="circle-small--inner">
                <div className="rectangle"></div>
              </div>
            </div>
            <div className="circle-dots-wrapper">
              <div className="dot-a-wrapper"><div className="dot-a dot-1"></div></div>
              <div className="dot-a-wrapper"><div className="dot-a dot-2"></div></div>
              <div className="dot-a-wrapper"><div className="dot-a dot-3"></div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeSection1Shapes;
