import React, {useState} from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import VisibilitySensor from 'react-visibility-sensor';


export default function HomeSection9() {
  
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    //console.log('section 9 inview')
    setIsInview(isVisible)
  }

  return (
    <StaticQuery
      query={graphql`
        query HomeSection9Query {
          wpPage(id: {eq: "cG9zdDo4"}) {
            acf_homepage {
              brands9 {
                logo {
                  altText
                  localFile{
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={acf_homepage => (
        <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
          {({isVisible}) =>
            <section id="section9" className={`section section9 home-section section--show-header is_inview trans_in scollTrigger ${isInview ? 'active' : ''}`}>
              <div className="brands-wrap">
                <h2 className="label">Brands that trust us</h2>
                <ul className="slides">
                { acf_homepage.wpPage.acf_homepage.brands9.map((item, i) => {            
                    return (
                      <li className="slide" key={'slide_'+i}>
                        <div className="image-wrap">
                          <div className="tint"></div>
                          
                          {item?.logo?.localFile?.childImageSharp?.fluid ?
                          <Img 
                          fluid={item?.logo.localFile.childImageSharp.fluid} 
                          alt={item?.logo?.altText} 
                          className="bg-cover BG bgStretch" />
                          : "Image not found" 
                        }
                        </div>
                      </li>
                    )
                  })
                }
                </ul>          
              </div>
          </section>
          }
        </VisibilitySensor>
      )}
    />
  )
}
