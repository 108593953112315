import React, {useEffect, useRef} from "react"
import parse from 'html-react-parser'
import HomeSection1Shapes from './shapes/HomeSection1-shapes';
import HomeBackgroundShapes from './shapes/Home-Background-Shapes'
import ScrollSVG from "../../assets/scrolldown.inline.svg";
import styled from 'styled-components';
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
import { Link, StaticQuery, graphql } from "gatsby"
gsap.registerPlugin(SplitText);

const  Section1 = styled.section`

`;

export default function HomeSection1() {
  const section1 = useRef();

  useEffect(() => {
    if(typeof document !== 'undefined'){
      // setTimeout(function(){
      //   section1.current.classList.add('is-active')
      // }.bind(this),1)
      
      // let text = heroText.innerHTML;
		  	var heroText_split = new SplitText('.split-text span', {type:"lines", linesClass:'lines'});

        heroText_split.lines.map(line => {
          let grandparent = line.parentNode.parentNode;
          let parent = line.parentNode;
          let originalHtml = line.innerHTML;
          let newHtml = `<div>${originalHtml}</div>`

          line.innerHTML = newHtml;

          grandparent.append(line);
          Array.from(grandparent.querySelectorAll('.lines')).map(line => {
            setTimeout(function(){
              line.classList.add('is_inview')
            }.bind(this), 1);
              
          })
          parent.style.display = 'none';

        })

    }
    
  
  }, [])
  return (
    <StaticQuery
      query={graphql`
        query HomeSection1Query {
          wpPage(id: {eq: "cG9zdDo4"}) {
            acf_homepage {
              button {
                buttonText
                externalLink
                fieldGroupName
                internalLinkText
              }
              headline1
              introText1
            }  
          }
        }
      `}
      render={acf_homepage => (
        <Section1 
        id="section-1" 
        className="section-anim section-1 scollTrigger" 
        ref={section1} 
        data-color1="#fd9c16" 
        data-color2="#ee6b4b" 
        data-start="top 0%" 
        data-end="top 30%"
        data-show-next="false"
        data-show-prev="false"
        data-show-pagination="false"
        data-case-index="0"
        data-next-trigger="trigger-2"
        data-prev-trigger="trigger-1"
        >
          {/* <div className="bg"></div> */}
          <div className="home-wrapper">
            <HomeSection1Shapes />
            <div className="vert-center section-anim__content">
              <h1 id="section1_heading" className="split-text heading heading--large shadow" data-split-type="words">
                <span>{acf_homepage.wpPage.acf_homepage.headline1}</span>
              </h1>
              <div className="text-wrap">
                <div className="before--wrap"><div className="rectangle-bg"></div></div>
                <div id="section1_text" className="desc-text">{parse(acf_homepage.wpPage.acf_homepage.introText1)}</div>
                {/* <Link to={acf_homepage.wpPage.acf_homepage.button.internalLinkText} className="btn btn--grad"><span>{acf_homepage.wpPage.acf_homepage.button.buttonText}</span></Link> */}
              </div>
            </div>
          </div>
          <HomeBackgroundShapes />
        </Section1>
      )}
    />
  )
}
