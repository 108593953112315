import React from "react"
import HomeCaseStudyTemplate from '../homepage/HomeCaseStudyTemplate'
import { StaticQuery, graphql } from "gatsby"

export default function HomeSection4() {
  return (
    <StaticQuery
      query={graphql`
        query HomeSection4Query {
          wpPage(id: {eq: "cG9zdDo4"}) {
            acf_homepage {
              label4
              headline4
              text4
              button4 {
                buttonText
                externalLink
                internalLinkText
                internalOrExternalLink
              }
              image4 {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={acf_homepage => (
        <HomeCaseStudyTemplate 
          label = {acf_homepage.wpPage.acf_homepage.label4}
          headline = {acf_homepage.wpPage.acf_homepage.headline4}
          text = {acf_homepage.wpPage.acf_homepage.text4}
          button = {acf_homepage.wpPage.acf_homepage.button4}
          image = {acf_homepage.wpPage.acf_homepage.image4}
          color1={'#9100cd'}
          color2={'#ce58d1'}
          start="top 40%"
          end="top 100%"
          index="4" 
          show_next="true"
          show_prev="true"
          show_pagination="true"
          case_index="100"
          next_trigger="trigger-4"
          prev_trigger="trigger-2"
        />
      )}
    />
  )
}
