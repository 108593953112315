import React from "react"
import HomeCaseStudyTemplate from '../homepage/HomeCaseStudyTemplate'
import { StaticQuery, graphql } from "gatsby"


export default function HomeSection3() {
  return (
    <StaticQuery
      query={graphql`
        query HomeSection3Query {
          wpPage(id: {eq: "cG9zdDo4"}) {
            acf_homepage {
              label3
              headline3
              text3
              button3 {
                buttonText
                externalLink
                internalLinkText
                internalOrExternalLink
              }
              image3 {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={acf_homepage => (
        <HomeCaseStudyTemplate 
          label = {acf_homepage.wpPage.acf_homepage.label3}
          headline = {acf_homepage.wpPage.acf_homepage.headline3}
          text = {acf_homepage.wpPage.acf_homepage.text3}
          button = {acf_homepage.wpPage.acf_homepage.button3}
          image = {acf_homepage.wpPage.acf_homepage.image3}
          color1={'#fd9c16'}
          color2={'#ee6b4b'}
          start="top 40%"
          end="top 100%"
          index="3" 
          show_next="true"
          show_prev="true"
          show_pagination="true"
          case_index="66.66666"
          next_trigger="trigger-3"
          prev_trigger="trigger-1"
        />
      )}
    />
  )
}
