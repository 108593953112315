import React, { useRef, useEffect, useState } from "react";
import Footer from "../components/globals/Footer";
import gsap, { TimelineLite } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Slider from "react-slick";
import SliderControls from "../components/homepage/SliderControls";
import HomeSection1 from "../components/homepage/HomeSection1";
import HomeSection2 from "../components/homepage/HomeSection2";
import HomeSection3 from "../components/homepage/HomeSection3";
import HomeSection4 from "../components/homepage/HomeSection4";
import HomeSection5 from "../components/homepage/HomeSection5";
import HomeSection6 from "../components/homepage/HomeSection6";
import HomeSection7 from "../components/homepage/HomeSection7";
import HomeSection8 from "../components/homepage/HomeSection8";
import HomeSection9 from "../components/homepage/HomeSection9";
import HomeSection10 from "../components/homepage/HomeSection10";
import BasicCallToAction from "../components/page/BasicCallToAction";
import HomeSectionAdditionalTextArea from "../components/homepage/HomeSectionAdditionalTextArea";
import AnimatedRectangles from "../components/homepage/shapes/AnimatedRectangles";
import AnimationTriggers from "../components/homepage/AnimationTriggers";
import VisibilitySensor from "react-visibility-sensor";
import { SchemaLinksToAbsolute } from "../helpers/SchemaLinksToAbsolute";

import "../sass/pages/_home.scss";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import VideoTranscriptionBlock from "../components/page/VideoTranscriptionBlock";
// import SeoHome from '../components/seoHome'

gsap.registerPlugin(ScrollTrigger);

const Homepage = ({ data }) => {
  //console.log(data)
  const homeScroll = useRef();
  const scroller = useRef();
  const sliderRef = useRef();
  const [nextSlide, setNextSlide] = useState();
  const [prevSlide, setPrevSlide] = useState();
  const [hasInit, setHasInit] = useState(false);
  const [scrollTrig, setScrollTrig] = useState();
  const [scrollTrig2, setScrollTrig2] = useState();

  const setRectanglePositions = (progress) => {
    if (typeof document !== "undefined") {
      const allBgRectangles = document.querySelectorAll(".rectangle-bg");
      allBgRectangles.forEach((rectangle, i) => {
        gsap.to(rectangle, {
          y: 150 * i - progress * 150 + "vh",
          x: -150 * i - progress * -150 + "vh",
        });
      });
    }
  };
  const resetRectanglePositions = () => {
    if (typeof document !== "undefined") {
      const rect1 = document.querySelector("#section-1 .rectangle-bg");
      gsap.to(rect1, {
        y: "0vh",
        x: "0vh",
      });
    }
  };

  const whatBrowser = () => {
    if (typeof window !== "undefined") {
      var sBrowser,
        sUsrAg = navigator.userAgent;
      let browserIs;
      const elements = document.querySelectorAll(".whatBrowser");

      if (sUsrAg.indexOf("Firefox") > -1) {
        //console.log( "firefox")
        browserIs = "firefox";
        elements.forEach((element) => element.classList.add(browserIs));
        return "firefox";
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
      } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        //console.log( "sansung")
        browserIs = "sansung";
        elements.forEach((element) => element.classList.add(browserIs));
        return "sansung";
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
      } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        //console.log("opera")
        browserIs = "opera";
        elements.forEach((element) => element.classList.add(browserIs));
        return "opera";
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
      } else if (sUsrAg.indexOf("Trident") > -1) {
        //console.log("ie")
        browserIs = "ie";
        elements.forEach((element) => element.classList.add(browserIs));
        return "ie";
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
      } else if (sUsrAg.indexOf("Edge") > -1) {
        //console.log("edge-legacy")
        browserIs = "edge-legacy";
        elements.forEach((element) => element.classList.add(browserIs));
        return "edge-legacy";
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
      } else if (sUsrAg.indexOf("Edg") > -1) {
        //console.log("edge")
        browserIs = "edge";
        elements.forEach((element) => element.classList.add(browserIs));
        return "edge";
        // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36 Edg/91.0.864.64
      } else if (sUsrAg.indexOf("Chrome") > -1) {
        //console.log('chrome')
        browserIs = "chrome";
        elements.forEach((element) => element.classList.add(browserIs));
        return "chrome";
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
      } else if (sUsrAg.indexOf("Safari") > -1) {
        //console.log('safari')
        browserIs = "safari";
        elements.forEach((element) => element.classList.add(browserIs));
        return "safari";
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
      } else {
        //console.log("unknown")
        browserIs = "unknown";
        elements.forEach((element) => element.classList.add(browserIs));
        return "unknown";
      }
    }
  };

  const mobileSliderResize = () => {
    const scroller = document.querySelector("#container-scroller");
    const elementsToResize = gsap.utils.toArray([
      ".section-anim",
      ".slider-wrap",
      ".slider-controls",
      ".animate-rectangles",
    ]);

    elementsToResize.forEach(
      (elem) => (elem.style.height = document.documentElement.clientHeight + "px")
    );
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = "visible";
    if (whatBrowser() === "safari") {
      mobileSliderResize();
      window.addEventListener("resize", mobileSliderResize);
      return () => {
        window.removeEventListener("resize", mobileSliderResize);
      };
    }
  });

  const updateBackgroundColor = (sectionColor1, sectionColor2) => {
    gsap.to(".animate-rectangles", { opacity: 1, duration: 1 });
    let b2 = `linear-gradient(to left, ${sectionColor1}, ${sectionColor2}`;
    gsap.to(".animate-rectangles", {
      ease: "none",
      duration: 1,
      background: b2,
    });
    gsap.to(".animate-rectangles .rect", {
      ease: "none",
      duration: 1,
      background: b2,
    });
  };

  const updateSlideRectangleColor = (sectionColor1, sectionColor2, slide) => {
    let b2 = `linear-gradient(to left, ${sectionColor1}, ${sectionColor2}`;
    gsap.to(slide, { ease: "none", duration: 1, background: b2 });
  };

  const hideArrows = () => {
    gsap.to(".pagination-dots", { opacity: 0, y: 100 });
    gsap.to(".arrow.next", { opacity: 0, x: 100 });
    gsap.to(".arrow.prev", { opacity: 0, x: -100 });
  };
  const showArrows = () => {
    gsap.fromTo(".pagination-dots", { opacity: 0, y: 100 }, { opacity: 1, y: 0 });
    gsap.fromTo(".arrow.next", { opacity: 0, x: 100 }, { opacity: 1, x: 0 });
    gsap.fromTo(".arrow.prev", { opacity: 0, x: -100 }, { opacity: 1, x: 0 });
  };

  const showCaseStudies = () => {
    let sections = gsap.utils.toArray(".section-anim");
    const landingBGShapes = document.querySelector("#background_shapes");
    const bg = document.querySelector(".animate-rectangles");
    let rectangles = gsap.utils.toArray(".animate-rectangles .rect-wrap");
    const bg2 = document.querySelector(".main-rectangles");
    gsap.to(bg, { opacity: 0, duration: 0.5, ease: "power2.inOut" });
    gsap.to(bg2, { opacity: 0, duration: 0.5, ease: "power2.inOut" });

    if (sections[0] !== undefined) {
      sections[0].classList.remove("is-active");
      landingBGShapes.classList.remove("trans_in");

      //console.log('trigger 1: enter')

      // Show Arrows
      setTimeout(showArrows, 800);

      const slides = Array.from(document.querySelectorAll(".slider-section"));
      const pagination = Array.from(document.querySelectorAll(".pagination-dots li"));
      slides[0].classList.add("is-active");
      pagination[0].classList.add("active");
      const col1 = slides[0].getAttribute("data-color1");
      const col2 = slides[0].getAttribute("data-color2");
      updateBackgroundColor(col1, col2);
      updateSlideRectangleColor(col1, col2, slides[0].querySelector(".rectangle-bg"));
      gsap.to(rectangles, {
        x: 100 * 2,
        y: -100 * 2,
        delay: 0.2,
        stagger: 0.1,
        duration: 1.5,
      });

      setHasInit(true);
    }
  };

  const scroll = () => {
    //console.log('scroll init')
    let sections = gsap.utils.toArray(".section-anim");
    let rectangles = gsap.utils.toArray(".animate-rectangles .rect-wrap");

    let wW = window.innerWidth;
    let wH = window.innerHeight;

    const landingBGShapes = document.querySelector("#background_shapes");

    let hasLoaded = false;

    const scroll = ScrollTrigger.create({
      trigger: ".scroll-wrapper",
      start: "top top",
      scroller: "#container-scroller",
      end: "bottom bottom",
      pin: ".scroll-wrapper-inner",
      anticipatePin: 1,
      pinSpacing: false,
      id: "scrollTrigger1",
      pinType: "fixed",
      // markers: true,
      onEnter: (self) => {
        // setScrollTrig(scroll);
        //console.log('hasLoaded', hasLoaded)
        if (hasLoaded === false) {
          gsap.from(rectangles, {
            x: -wW + "px",
            y: wH + "px",
            delay: 0.2,
            stagger: 0.05,
            duration: 2,
          });
          landingBGShapes.classList.add("trans_in");
          setTimeout(
            function () {
              sections[0].classList.add("is-active");
              const col1 = sections[0].getAttribute("data-color1");
              const col2 = sections[0].getAttribute("data-color2");
              updateBackgroundColor(col1, col2);
            }.bind(this),
            hasLoaded === false ? 500 : 1
          );
          hasLoaded = true;
        }
      },

      onLeave: (self) => {
        //console.log('stick: onLeave')
      },
      onEnterBack: (self) => {
        //console.log('stick: onEnterBack')
        const bg = document.querySelector(".animate-rectangles");
        const bg2 = document.querySelector(".main-rectangles");
        gsap.to(bg, { opacity: 1, duration: 0.5, ease: "power2.inOut" });
        gsap.to(bg2, { opacity: 1, duration: 0.5, ease: "power2.inOut" });
      },
    });

    const scroll2 = ScrollTrigger.create({
      trigger: ".scroll-wrapper",
      start: "top -10%",
      end: "bottom 200%",
      id: "scrollTrigger2",
      scroller: "#container-scroller",
      // pinReparent:true,
      // anticipatePin:1,
      // pinType: "fixed",
      // markers: true,
      onEnter: (self) => {
        // setScrollTrig2(scroll2)
        //console.log('trigger 1: enter')
        showCaseStudies();
      },
      //onToggle: self => console.log("toggled2, isActive:", self.isActive),
      onEnterBack: (self) => {
        if (sections[0] !== undefined) {
          //console.log('trigger 1: enterBack')
          const bg = document.querySelector(".animate-rectangles");
          const bg2 = document.querySelector(".main-rectangles");
          gsap.to(bg, { opacity: 1, duration: 0.5, ease: "power2.inOut" });
          gsap.to(bg2, { opacity: 1, duration: 0.5, ease: "power2.inOut" });
          sections[0].classList.add("is-active");
          landingBGShapes.classList.add("trans_in");
          const slides = Array.from(document.querySelectorAll(".slider-section"));
          slides.forEach((slide) => slide.classList.remove("is-active"));
          const col1 = sections[0].getAttribute("data-color1");
          const col2 = sections[0].getAttribute("data-color2");
          updateBackgroundColor(col1, col2);
          // Hide Arrows
          hideArrows();

          gsap.to(rectangles, {
            x: 100 * 1,
            y: -100 * 1,
            delay: 0.2,
            stagger: 0.1,
            duration: 1.5,
          });
        }
      },
    });
  };

  const [isInview, setIsInview] = useState(false);

  function inviewOnChange(isVisible) {
    setIsInview(isVisible);
  }

  const [offset, setOffset] = useState();
  useEffect(() => {
    if (typeof window !== "undefined") {
      const ww = window.innerWidth;

      //console.log(ww)

      if (ww > 1024) {
        setOffset(500);
      } else {
        setOffset(0);
      }
    }
  });

  useEffect(() => {
    // setRectanglePositions(0)
    //setRenderSlick(true)

    if (typeof window !== "undefined") {
      setTimeout(hideArrows, 810);
      ScrollTrigger.getById("scrollTrigger1").enable();
      ScrollTrigger.getById("scrollTrigger2").enable();
      ScrollTrigger.getById("scrollTrigger1").refresh();
      ScrollTrigger.getById("scrollTrigger2").refresh();
    }
    // slickSlider.slickPause();
    return () => {
      //console.log('scrollTrigger refresh')
      ScrollTrigger.getById("scrollTrigger1").disable();
      ScrollTrigger.getById("scrollTrigger2").disable();
    };
  }, []);

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="prev arrow" onClick={onClick}>
        <div className="indent_text">Go to previous section</div>
      </div>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div className="next arrow" onClick={onClick}>
        <div className="indent_text">Go to next section</div>
      </div>
    );
  }

  function beforeChange(index, current) {
    // console.log(index)
    const slides = Array.from(document.querySelectorAll(".slider-section"));

    if (current === slides.length) {
      slides.forEach((slide) => slide.classList.remove("is-active"));
      slides[0].classList.add("is-active");

      const col1 = slides[0].getAttribute("data-color1");
      const col2 = slides[0].getAttribute("data-color2");
      updateBackgroundColor(col1, col2);
      updateSlideRectangleColor(col1, col2, slides[0].querySelector(".rectangle-bg"));

      let rectangles = gsap.utils.toArray(".animate-rectangles .rect-wrap");
      gsap.to(rectangles, {
        x: 100 * index,
        y: -100 * index,
        delay: 0.2,
        stagger: 0.1,
        duration: 1.5,
      });
    } else {
      slides.forEach((slide) => slide.classList.remove("is-active"));
      slides[current].classList.add("is-active");

      const col1 = slides[current].getAttribute("data-color1");
      const col2 = slides[current].getAttribute("data-color2");
      // gsap.to(slides[current], {pointerEvents:'all'})
      updateBackgroundColor(col1, col2);
      updateSlideRectangleColor(col1, col2, slides[current].querySelector(".rectangle-bg"));

      let rectangles = gsap.utils.toArray(".animate-rectangles .rect-wrap");
      gsap.to(rectangles, {
        x: 100 * ((index + 1) * 3),
        y: -100 * ((index + 1) * 3),
        delay: 0.2,
        stagger: 0.1,
        duration: 1.5,
      });
    }
  }

  return (
    <div id="home">
      <div className={`home whatBrowser`} ref={homeScroll}>
        <Seo
          title={data.wpPage.seo.title}
          description={data.wpPage.seo.metaDesc}
          page="homepage"
          date={data.wpPage.date}
          slug="/"
          robotsFollow={data.wpPage.seo.metaRobotsNofollow}
          robotsIndex={data.wpPage.seo.metaRobotsNoindex}
          canonicalUrl={data.wpPage.seo.canonical}
        />
        {data.wpPage.seo.schema.raw !== undefined ? (
          <SchemaLinksToAbsolute schemaRaw={data.wpPage.seo.schema.raw} />
        ) : (
          ""
        )}
        <div className={`scroll-wrapper`}>
          <div className="scroll-wrapper-inner pinned">
            <HomeSection1 />
            <div className={`slider-wrap whatBrowser`} ref={sliderRef}>
              <Slider
                dots={true}
                dotsClass={"pagination-dots"}
                infinite={true}
                speed={500}
                fade={true}
                slidesToShow={1}
                slidesToScroll={1}
                onInit={scroll}
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
                //  afterChange={hasInit === true ? slickSlider.slickPlay() : ''}
                beforeChange={(index, current) => beforeChange(index, current)}
                // ref={
                //   slider => (setSlickSlider(slider))
                // }
              >
                <HomeSection2 />
                <HomeSection3 />
                <HomeSection4 />
              </Slider>
            </div>
            <SliderControls nextSlide={nextSlide} prevSlide={prevSlide} />
            <AnimatedRectangles />
            <div className="main-rectangles">
              <div className="background-rectangle"></div>
              <div className="background-rectangle"></div>
              <div className="background-rectangle"></div>
              <div className="background-rectangle"></div>
              <div className="background-rectangle"></div>
            </div>
          </div>
        </div>

        <div className={`scroll-wrapper2 clearfix whatBrowser`}>
          <div className="scroll-wrapper2--inner clearfix" ref={scroller}>
            <HomeSectionAdditionalTextArea />
            <HomeSection9 />
            <HomeSection5 />
            <VideoTranscriptionBlock text={data.wpPage.acf_homepage.section5VideoTranscript} />
            <HomeSection6 />
            <div className="section7-8-wrap inview_check2">
              <VisibilitySensor
                partialVisibility={true}
                offset={{ bottom: offset }}
                active={!isInview}
                onChange={inviewOnChange}>
                {({ isVisible }) => (
                  <>
                    <HomeSection7 isInview={isInview} />
                    <HomeSection8 isInview={isInview} />
                  </>
                )}
              </VisibilitySensor>
              <div className="bg-shape bg-shape-1 rect inview"></div>
              <div className="bg-shape bg-shape-2 rect inview"></div>
              <div className="bg-shape bg-shape-3 circle inview"></div>
              <div className="bg-shape bg-shape-4 circle inview"></div>
            </div>
            <HomeSection10 />
            <BasicCallToAction
              heading={data.wpPage.acf_homepage.callToAction10.ctaHeading}
              buttons={data.wpPage.acf_homepage.callToAction10.ctaButtons}
              removeBottomMargin={data.wpPage.acf_homepage.callToAction10.addMarginBottom}
              autoHeight={true}
            />
            <Footer />
          </div>
        </div>
        <AnimationTriggers />
      </div>
    </div>
  );
};

export default Homepage;

export const query = graphql`
  {
    wpPage(id: { eq: "cG9zdDo4" }) {
      seo {
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        schema {
          raw
        }
      }
      date
      acf_homepage {
        section5VideoTranscript
        callToAction10 {
          addMarginBottom
          ctaHeading
          ctaButtons {
            buttonText
            pageLink {
              ... on WpPage {
                id
                link
              }
            }
          }
        }
      }
    }
  }
`;
