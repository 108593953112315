import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import VisibilitySensor from "react-visibility-sensor";
// import Video from "../../assets/video/homepage_infographic.mp4"
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";

gsap.registerPlugin(SplitText);

export default function HomeSection5() {
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange(isVisible) {
    setIsInview(isVisible);
  }

  useEffect(() => {
    if (typeof document !== "undefined") {
      var heroText_split = new SplitText("#section5_heading span", {
        type: "lines",
        linesClass: "lines",
      });

      //console.log(heroText_split)
      heroText_split.lines.map((line) => {
        let grandparent = line.parentNode.parentNode;
        let parent = line.parentNode;
        let originalHtml = line.innerHTML;
        let newHtml = `<div>${originalHtml}</div>`;

        line.innerHTML = newHtml;

        grandparent.append(line);
        Array.from(grandparent.querySelectorAll(".lines")).map((line) => {
          setTimeout(
            function () {
              line.classList.add("is_inview");
            }.bind(this),
            1
          );
        });
        parent.style.display = "none";
      });
    }
  }, []);
  return (
    <StaticQuery
      query={graphql`
        query HomeSection5Query {
          wpPage(id: { eq: "cG9zdDo4" }) {
            acf_homepage {
              heading5
              text5
              button5 {
                buttonText
                externalLink
                fieldGroupName
                internalLinkText
                internalOrExternalLink
              }
            }
          }
        }
      `}
      render={(acf_homepage) => (
        <VisibilitySensor
          partialVisibility={true}
          offset={{ bottom: 120 }}
          active={!isInview}
          onChange={inviewOnChange}
        >
          {({ isVisible }) => (
            <section
              id="section-5"
              className={`section section5 home-section section-anim scollTrigger ${
                isInview ? "is-active" : ""
              }`}
              data-color1="#ffffff"
              data-color2="#ffffff"
              data-start="top 0%"
              data-end="top 40%"
              data-show-next="false"
              data-show-prev="false"
              data-show-header="true"
              data-show-pagination="false"
              data-case-index="4"
              data-next_trigger="trigger-6"
              data-prev_trigger="trigger-4"
              data-hide-background-shapes="true"
            >
              <div className="wrapper-large">
                <div className="videoWrap desktop">
                  <iframe
                    src="https://player.vimeo.com/video/797390868?h=30dd1eab36&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    title="What_clients_can_expect_from_seo_agency_york.mp4"
                  ></iframe>
                  {/* <video playsInline loop="loop" controls>
                    <source
                      src={
                        "https://player.vimeo.com/progressive_redirect/playback/797390868/rendition/1080p/file.mp4?loc=external&signature=8bbace6f2f92f0e26cfe0824f8f9897bc9d84c4736dacda3273065be30685121"
                      }
                      type="video/mp4"
                    ></source>
                  </video> */}
                </div>
                <div className="home-wrapper">
                  <div className="home-wrapper-inner">
                    <div className="label">
                      <span>
                        A digital marketing agency with real expertise
                      </span>
                    </div>
                    <h2
                      id="section5_heading"
                      className="heading heading--medium"
                      data-split-type="lines"
                    >
                      <span className="setter">
                        {acf_homepage.wpPage.acf_homepage.heading5}
                      </span>
                    </h2>
                    <div className="text-wrap">
                      <div id="section5_text" className="desc-text">
                        {parse(acf_homepage.wpPage.acf_homepage.text5)}
                      </div>
                      <Link
                        to={
                          acf_homepage.wpPage.acf_homepage.button5
                            .internalLinkText
                        }
                        className="btn btn--grad btn--grad--orange"
                      >
                        <span>
                          {acf_homepage.wpPage.acf_homepage.button5.buttonText}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </VisibilitySensor>
      )}
    />
  );
}
